import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Hero from '../components/hero'
import PageSlider from '../containers/page-slider'
import Section from '../containers/elements/client-logo/section-home'
import { slugify, truncateString } from '../utils/utilFunctions'

const IndexPage = ({ pageContext, location }) => {
  const pageData = useStaticQuery(graphql`
    query RegionPageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "e9043380-b7da-53da-b06b-7e953c9543b3" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            subpages {
              ... on Node {
                ... on ContentfulPagina {
                  id
                  name
                  headerTitel
                  headerSubtitel
                }
              }
            }
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/over-de-regio`
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
    subpages,
  } = pageData.page.edges[0].node
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  const pageList = subpages.map((page) => {
    page.title = page.headerTitel
    page.description = truncateString(page.headerSubtitel, 60)
    page.url = `/over-de-regio/${slugify(page.name)}`
    page.btnText = `${page.name}`

    return page
  })

  return (
    <>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
        />

        <PageSlider list={pageList} offset={true} />
        <Section />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
